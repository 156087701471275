import React from 'react'
import { Link } from 'react-router-dom'

const footer = () => {
  return (
    <>
      {/*  Call To Action  */}
      <section className="theme-bg call-to-act-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="call-to-act">
                <div className="call-to-act-head">
                  <h3>Want to Become a Real Estate Agent?</h3>
                  <span>We'll help you to grow your career and growth.</span>
                </div>
                <a href="#." className="btn btn-call-to-act">
                  SignUp Today
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Call To Action End */}
      {/* Footer Start */}
      <footer className="dark-footer skin-dark-footer">
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="footer-widget">
                  <img src="assets/img/logo-light.png" className="img-footer" alt="" />
                  <ul className='addressListBx'>
                    <li>
                      <i className='ti-location-pin'></i>
                      <span>
                        <b>Office : </b>
                        Module No. 301, 3rd Floor, Tower-II, BN 9, Webel IT Park, Sector V, Salt Lake, Kolkata - 91
                      </span>
                    </li>
                    <li>
                      <i className='ti-email'></i>
                      <span>
                        <b>Email : </b>
                        <Link to="mailto:support@indianniwas.com">support@indianniwas.com</Link> 
                      </span>
                    </li>
                    <li>
                      <i className='ti-mobile'></i>
                      <span>
                        <b>Phone : </b>
                        <Link to="tel:+919051755536">+91 9051755536</Link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="footer-widget">
                  <h4 className="widget-title">Quick Link</h4>
                  <ul className="footer-menu">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/AboutUs">About Us</Link></li>
                    <li><Link to="/ContactUs">Contact Us</Link></li>                    
                    <li><Link to="/TermsConditions">Terms & Conditions</Link></li>
                    <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>                    
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="footer-widget">
                  <h4 className="widget-title">Our Projects</h4>
                  <ul className="footer-menu">
                    <li><Link to="/BaseraSmartCity">Basera Smart City</Link></li>
                    <li><Link to="/EcoSmartCityPhaseII">Eco Smart City - Phase II</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget">
                  <h4 className="widget-title">Download Apps</h4>
                  <a href="javascript:void(0)" className="other-store-link">
                    <div className="other-store-app">
                      <div className="os-app-icon">
                        <i className="lni-playstore theme-cl" />
                      </div>
                      <div className="os-app-caps">
                        Google Play
                        <span>Get It Now</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <p className="mb-0">&copy; Copyright 2023 indianniwas.com All Rights Reserved.</p>
              </div>
              <div className="col-lg-6 col-md-6 text-right">
                <ul className="footer-bottom-social">
                  <li>
                    <a href="#.">
                      <i className="ti-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#.">
                      <i className="ti-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#.">
                      <i className="ti-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="ti-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer End */}
      {/* Log In Modal */}
      <div
        className="modal fade"
        id="login"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="registermodal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered login-pop-form"
          role="document"
        >
          <div className="modal-content" id="registermodal">
            <span className="mod-close" data-dismiss="modal" aria-hidden="true">
              <i className="ti-close" />
            </span>
            <div className="modal-body">
              <h4 className="modal-header-title">Log In</h4>
              <div className="login-form">
                <form>
                  <div className="form-group">
                    <label>User Name</label>
                    <div className="input-with-icon">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username"
                      />
                      <i className="ti-user" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="input-with-icon">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="*******"
                      />
                      <i className="ti-unlock" />
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-md full-width pop-login"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
              <div className="modal-divider">
                <span>Or login via</span>
              </div>
              <div className="social-login mb-3">
                <ul>
                  <li>
                    <a href="#" className="btn connect-fb">
                      <i className="ti-facebook" />
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="#" className="btn connect-twitter">
                      <i className="ti-twitter" />
                      Twitter
                    </a>
                  </li>
                </ul>
              </div>
              <div className="text-center">
                <p className="mt-5">
                  <a href="#" className="link">
                    Forgot password?
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
      {/* Sign Up Modal */}
      <div
        className="modal fade signup"
        id="signup"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="sign-up"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered login-pop-form"
          role="document"
        >
          <div className="modal-content" id="sign-up">
            <span className="mod-close" data-dismiss="modal" aria-hidden="true">
              <i className="ti-close" />
            </span>
            <div className="modal-body">
              <h4 className="modal-header-title">Sign Up</h4>
              <div className="login-form">
                <form>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-with-icon">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                          />
                          <i className="ti-user" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-with-icon">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                          />
                          <i className="ti-email" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-with-icon">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Username"
                          />
                          <i className="ti-user" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-with-icon">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="*******"
                          />
                          <i className="ti-unlock" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-with-icon">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="123 546 5847"
                          />
                          <i className="lni-phone-handset" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-with-icon">
                          <select className="form-control">
                            <option>As a Customer</option>
                            <option>As a Agent</option>
                            <option>As a Agency</option>
                          </select>
                          <i className="ti-briefcase" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-md full-width pop-login"
                    >
                      Sign Up
                    </button>
                  </div>
                </form>
              </div>
              <div className="modal-divider">
                <span>Or login via</span>
              </div>
              <div className="social-login mb-3">
                <ul>
                  <li>
                    <a href="#" className="btn connect-fb">
                      <i className="ti-facebook" />
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="#" className="btn connect-twitter">
                      <i className="ti-twitter" />
                      Twitter
                    </a>
                  </li>
                </ul>
              </div>
              <div className="text-center">
                <p className="mt-5">
                  <i className="ti-user mr-1" />
                  Already Have An Account?{" "}
                  <a href="#" className="link">
                    Go For LogIn
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Modal */}
      <a id="back2Top" className="top-scroll" title="Back to top" href="#">
        <i className="ti-arrow-up" />
      </a>
      {/* Main Wrapper End */}
    </>
  )
}

export default footer
