import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import toast from "react-hot-toast";



const ProjectList = () => {

    const [mobile, setMobile] = useState("");
  const [email,setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [lo, setlo] = useState(false)

  const handleMobileChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    setMobile(inputValue.slice(0, 10));
  };

  const handlesubmit = async(e)=>{
    e.preventDefault()
    try {
      setlo(true)
      if(!email || !mobile || !message){
        toast.error("all field are  required")
        return
      }else{

        const req = await fetch('https://indian-niwas.onrender.com/api/contact-us',{
            method:"POST",
            headers:{
                "Content-Type": "application/json",
            },
            body : JSON.stringify({
                "email": email,
                "mobile": mobile,
                "message": message
            })
        })
        const res  = await req.json()
        if(req.ok){
          toast.success(res.message)
        }else{
          toast.error(res.message)
        }
      }

    } catch (error) {
        
    }finally{
      setEmail("")
      setMobile("")
      setMessage("")
      setlo(false)
    }

  }

    return (
        <>
            {/* page banner part start */}
            <section className="spd-wrap bgThemeGray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="slide-property-detail">
                                <div className="slide-property-first">
                                    <div className="pr-price-into">
                                        <h2>Eco Smart City - Phase II</h2>
                                        <span>
                                            <i className="lni-map-marker" />   I-land Park, New Town, Beside Manicasa 2 opp (Eco Space) Kolkata - 700156
                                        </span>
                                    </div>
                                </div>
                                <div className="slide-property-sec">
                                    <a className='btn btn-warning text-white m0' href="#."><i className='ti-download'></i> &nbsp;Download Brochures</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* page banner part end */}

            {/* page body part start */}
            <section className="gray">
                <div className="container">
                    <div className="row">
                        {/* property main detail */}
                        <div className="col-lg-8 col-md-12 col-sm-12">

                            {/******** slider start here ********/}
                            <div className='mb40'>
                                <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                                    <SwiperSlide><img src="assets/img/eco-smart-city-phase-II-1.webp" className="d-block w-100" alt="Basera Smart City" /></SwiperSlide>
                                    <SwiperSlide><img src="assets/img/eco-smart-city-phase-II-2.webp" className="d-block w-100" alt="Basera Smart City" /></SwiperSlide>
                                </Swiper>
                            </div>
                            {/******** slider end here ********/}

                            {/* Single Block Wrap */}
                            <div className="block-wrap">
                                <div className="block-body">
                                    <p className='m0'>Mauza:~ Jojra/Chaughariya,Besides Rajbati,Rajarhat ,Newtown Kolkata</p>
                                </div>
                            </div>

                            {/* Single Block Wrap */}
                            <div className="block-wrap">
                                <div className="block-header">
                                    <h4 className="block-title">Ameneties</h4>
                                </div>
                                <div className="block-body">
                                    <ul className="avl-features third">
                                        <li>Swimming pool</li>
                                        <li>Club house</li>
                                        <li>Community Hall</li>
                                        <li>Connercial shop</li>
                                        <li>Play grouud</li>
                                        <li>Children's Park</li>
                                        <li>Power backup</li>
                                        <li>Security (24/7)</li>
                                        <li>Wifi facility</li>
                                        <li>Cctv secrety</li>
                                        <li>Pick & Drop service</li>
                                        <li>Multiple parking</li>
                                        <li>Water purifying plant</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="block-wrap">
                                <div className="block-header">
                                    <h4 className="block-title">Essential Information</h4>
                                </div>
                                <div className="block-body">
                                    <div className="nearby-wrap">
                                        <div className="neary_section_list">
                                            <div className="neary_section">
                                                <div className="neary_section_first">
                                                    <h4 className="nearby_place_title">KOLKATA AIRPORT</h4>
                                                </div>
                                                <div className="neary_section_last">
                                                    <div className="nearby_place_rate good">30 Minute (approx)</div>
                                                </div>
                                            </div>
                                            <div className="neary_section">
                                                <div className="neary_section_first">
                                                    <h4 className="nearby_place_title">City Center II Metro Station</h4>
                                                </div>
                                                <div className="neary_section_last">
                                                    <div className="nearby_place_rate mid">20 Minute (approx)</div>
                                                </div>
                                            </div>
                                            <div className="neary_section">
                                                <div className="neary_section_first">
                                                    <h4 className="nearby_place_title">Golf Arena</h4>
                                                </div>
                                                <div className="neary_section_last">
                                                    <div className="nearby_place_rate high">20 Minute (approx)</div>
                                                </div>
                                            </div>
                                            <div className="neary_section">
                                                <div className="neary_section_first">
                                                    <h4 className="nearby_place_title">Eco Park</h4>
                                                </div>
                                                <div className="neary_section_last">
                                                    <div className="nearby_place_rate poor">20 Minute (approx)</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Single Block Wrap */}
                            <div className="block-wrap">
                                <div className="block-header">
                                    <h4 className="block-title">Floor Plan</h4>
                                </div>
                                <div className="block-body">
                                    <img src="assets/img/eco-smart-city-phaseII-map.webp" className='width100' alt="" />
                                </div>
                            </div>
                            {/* Single Block Wrap */}
                            <div className="block-wrap">
                                <div className="block-header">
                                    <h4 className="block-title">Location</h4>
                                </div>
                                <div className="block-body">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117881.8472913219!2d88.41039837788871!3d22.586293442210472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0275350398a5b9%3A0x75e165b244323425!2sNewtown%2C%20Kolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1701861505891!5m2!1sen!2sin"
                                        width="100%"
                                        height={300}
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />

                                </div>
                            </div>

                        </div>
                        {/* property Sidebar */}
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="page-sidebar">

                                {/* Quick Enquiry form start */}
                                <div className="agent-widget">
                                    <h4 className='mb15'>Quick Enquiry</h4>
                                    <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={mobile}
                      onChange={handleMobileChange}
                      className="form-control"
                      placeholder="Your Phone"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      value={message}
                      onChange={(e)=>setMessage(e.target.value)}
                      required
                    />
                  </div>
                  <button onClick={handlesubmit} className="btn btn-theme full-width">
                    {lo ? "Sending....":"Send Message"}
                  </button>
                                </div>
                                {/* Quick Enquiry form end */}

                                {/* Featured Property */}
                                <div className="sidebar-widgets">
                                    <h4>Featured Property</h4>
                                    <div className="sidebar_featured_property">

                                        {/* item start */}
                                        <div className="sides_list_property">
                                            <div className="sides_list_property_thumb">
                                                <img src="assets/img/basera-smart-city-banner-1.webp" className="img-fluid" alt="" />
                                            </div>
                                            <div className="sides_list_property_detail">
                                                <h4>
                                                    <Link to="/BaseraSmartCity">Basera Smart City</Link>
                                                </h4>
                                                <span>
                                                    <i className="ti-location-pin" />
                                                    NORTH 24 PGS, KOLKATA
                                                </span>
                                            </div>
                                        </div>
                                        {/* item end */}

                                        {/* item start */}
                                        <div className="sides_list_property">
                                            <div className="sides_list_property_thumb">
                                                <img src="assets/img/eco-smart-city-phase-II-1.webp" className="img-fluid" alt="" />
                                            </div>
                                            <div className="sides_list_property_detail">
                                                <h4>
                                                    <Link to="/EcoSmartCityPhaseII">Eco Smart City - Phase II</Link>
                                                </h4>
                                                <span>
                                                    <i className="ti-location-pin" />
                                                    NEWTOWN, KOLKATA
                                                </span>
                                            </div>
                                        </div>
                                        {/* item end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* page body part end */}
        </>
    )
}

export default ProjectList