import React from 'react'
import Banner from '../banner/Banner'
import MidIndex from '../mid-index/MidIndex'


const Home = () => {
  return (
    <>
        <Banner />
        <MidIndex />
    </>
  )
}

export default Home
