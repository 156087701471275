import React from 'react'

const ProjectList = () => {
    return (
        <>
            {/* page banner part start */}
            <div
                className="image-cover page-title"
                style={{ background: "url(assets/img/a.jpg) no-repeat" }}
                data-overlay={6}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <h2 className="ipt-title">Privacy Policy</h2>
                            <span className="ipn-subtitle text-light">
                            Build Your Dream Home with Indian Niwas
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* page banner part end */}

            {/* page body part start */}
            <section>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="story-wrap explore-content">
                                <p>At Indian Niwas, we understand that owning a home is more than just a dream; it's a symbol of success, stability, and happiness. That's why we're committed to helping you find your perfect plot of land, within your budget, and in a location that suits your lifestyle. We believe that everyone deserves the opportunity to own their own home, regardless of their financial situation. That's why we offer a variety of affordable plots to choose from, all conveniently located near major amenities and transportation hubs. Our team of experienced professionals will work with you every step of the way, from finding the perfect plot to navigating the paperwork and construction process. We're here to make sure that your dream home becomes a reality. Take the first step towards building your dream home. We'll help you find the perfect plot and make your dream home a reality.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* page body part end */}
        </>
    )
}

export default ProjectList