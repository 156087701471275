import React from 'react'

const Banner = () => {
    return (
        <>
            {/* Banner Part Start */}
            <div className="image-cover hero-banner" style={{ background: "url(assets/img/a.jpg) no-repeat" }}>
                <div className="container">
                    <div className="hero-search-wrap">
                        <div className="hero-search">
                            <h1>Find Your Dream</h1>
                        </div>
                        <div className="hero-search-content">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <div className="input-with-icon">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Neighborhood"
                                            />
                                            <i className="ti-search" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-with-icon">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Minimum"
                                            />
                                            <i>₹</i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-with-icon">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Maximum"
                                            />
                                            <i>₹</i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-with-icon">
                                            <select id="bedrooms" className="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                            </select>
                                            <i className="fas fa-bed" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <div className="input-with-icon">
                                            <select id="bathrooms" className="form-control">
                                                <option value="">&nbsp;</option>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                            </select>
                                            <i className="fas fa-bath" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-search-action">
                            <a href="#" className="btn search-btn">
                                Search Result
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Banner Part End */}
        </>
    )
}

export default Banner
