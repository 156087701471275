import React, { useState } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";


const ProjectList = () => {
  const [mobile, setMobile] = useState("");
  const [email,setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [lo, setlo] = useState(false)


  const handleMobileChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    setMobile(inputValue.slice(0, 10));
  };

  const handlesubmit = async(e)=>{
    e.preventDefault()
    try {
      setlo(true)
      if(!email || !mobile || !message){
        toast.error("all field are  required")
        return
      }else{

        const req = await fetch('https://indian-niwas.onrender.com/api/contact-us',{
            method:"POST",
            headers:{
                "Content-Type": "application/json",
            },
            body : JSON.stringify({
                "email": email,
                "mobile": mobile,
                "message": message
            })
        })
        const res  = await req.json()
        if(req.ok){
          toast.success(res.message)
        }else{
          toast.error(res.message)
        }
      }

    } catch (error) {
        
    }finally{
      setEmail("")
      setMobile("")
      setMessage("")
      setlo(false)
    }

  }


  return (
    <>
      {/* page banner part start */}
      <div
        className="image-cover page-title"
        style={{ background: "url(assets/img/a.jpg) no-repeat" }}
        data-overlay={6}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h2 className="ipt-title">Contact Us</h2>
              <span className="ipn-subtitle text-light">
                Build Your Dream Home with Indian Niwas
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* page banner part end */}

      {/* page body part start */}
      <section>
        <div className="container">
          {/* row Start */}
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="card bg-light">
                <div class="card-header">
                  <h4 className="m0">Send us a message</h4>
                  <p className="m0">You will hear from us at the earliest!</p>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control simple bg-white"
                          placeholder="youremail@mail.com"
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          type="text"
                          value={mobile}
                          onChange={handleMobileChange}
                          placeholder="0000000000"
                          className="form-control simple bg-white"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      className="form-control simple bg-white"
                      defaultValue={""}
                      value={message}
                      onChange={(e)=>setMessage(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <button onClick={handlesubmit} className="btn btn-theme" type="submit">
                      {lo ? "Sending...." : "Submit Request"}  
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="contact-info">
                <h4 className="m0">Contact Information:</h4>
                <hr />
                <div className="cn-info-detail">
                  <div className="cn-info-icon">
                    <i className="ti-home" />
                  </div>
                  <div className="cn-info-content">
                    <h4 className="cn-info-title">Indian Niwas</h4>
                    <p className="width90">
                      Module No. 301, 3rd Floor, Tower-II, BN 9, Webel IT Park,
                      Sector V, Salt Lake, Kolkata, West Bengal 700091
                    </p>
                  </div>
                </div>
                <div className="cn-info-detail">
                  <div className="cn-info-icon">
                    <i className="ti-email" />
                  </div>
                  <div className="cn-info-content">
                    <h4 className="cn-info-title">Drop A Mail</h4>
                    <Link to="mailto:support@indianniwas.com">
                      support@indianniwas.com
                    </Link>
                  </div>
                </div>
                <div className="cn-info-detail">
                  <div className="cn-info-icon">
                    <i className="ti-mobile" />
                  </div>
                  <div className="cn-info-content">
                    <h4 className="cn-info-title">Call Us</h4>
                    <Link to="+919051755536">+91 9051755536</Link>
                  </div>
                </div>
              </div>
              {/* map part start */}
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.9684543867475!2d88.43314041443416!3d22.58028313838024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a027832db82acc9%3A0x6ae439870fed8918!2sHTSM%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1643960498970!5m2!1sen!2sin"
                  width="100%"
                  height={295}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
              {/* map part end */}
            </div>
          </div>
          {/* /row */}
        </div>
      </section>
      {/* page body part end */}
    </>
  );
};

export default ProjectList;
