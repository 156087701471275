import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
    return (
        <>
            {/* Main wrapper start */}
            <div id="main-wrapper">
                {/* Start Navigation */}
                <div className="top-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="cn-info">
                                    <ul>
                                        <li>
                                            <i className="lni-phone-handset" /> Call :&nbsp;
                                            <Link className='text-white' to="tel:+919051755536">+91 9051755536</Link>
                                        </li>
                                        <li>
                                            <i className="ti-email" /> Mail at :&nbsp;
                                            <Link className='text-white' to="mailto:support@indianniwas.com">support@indianniwas.com</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <ul className="top-social">
                                    <li>
                                        <a href="#.">
                                            <i className="lni-facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#.">
                                            <i className="lni-linkedin" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#.">
                                            <i className="lni-instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#.">
                                            <i className="lni-twitter" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/*********** new navigation work start here ***********/}
                <nav className="navbar navbar-expand-lg navbar-light header-light">
                    <article className='container'>
                        <Link className="navbar-brand" to="/">
                            <img src="assets/img/logo.png" className="logo" alt="" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNav" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="mainNav">
                            <div className='row width100'>
                                <div className='col-md-9 col-12 navWarp'>
                                    <ul className="navbar-nav">
                                        <li className="nav-item active">
                                            <Link className="nav-link" to="/">Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/AboutUs">About Us</Link>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-toggle="dropdown" aria-expanded="false">Properties</a>
                                            <div className="dropdown-menu">
                                                <Link className="dropdown-item" to="/BaseraSmartCity">Basera Smart City</Link>
                                                <Link className="dropdown-item" to="/EcoSmartCityPhaseII">Eco Smart City - Phase II</Link>
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/">Buy</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/">Rent</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/">Sell</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-md-3 col-12'>
                                    <ul className="nav-menu nav-menu-social align-to-right">
                                        <li className="add-listing theme-bg">
                                            <Link to="/ContactUs">Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </article>
                </nav>
                {/*********** new navigation work end here ***********/}

                {/* End Navigation */}
                <div className="clearfix" />
            </div>
        </>
    )
}

export default Header
