import React from 'react'
import { Link } from 'react-router-dom'

const MidIndex = () => {
  return (
    <>
      {/* ============================ Property List Start ================================== */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="sec-heading2 center  mb-3">
                <div className="sec-left">
                  <h3>New &amp; featured Property</h3>
                  <p>Find new &amp; featured property for you.</p>
                </div>
                <div className="sec-right">
                  <a href="half-map.html">
                    View All
                    <i className="ti-angle-double-right ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* item start */}
            <div className="col-md-6">
              <div className="property_item classical-list">
                <div className="image">
                  <Link to="/BaseraSmartCity">
                    <img src="assets/img/basera-smart-city-banner-1.webp" alt="latest property" className="img-fluid" />
                  </Link>
                  <div className="sb-date">
                    <span className="tag">
                      <i className="ti-calendar" /> From 1/8/2023
                    </span>
                  </div>
                  <span className="tag_t">Rs.551/- PER SQ. FT</span>
                </div>
                <div className="proerty_content">
                  <div className="proerty_text">
                    <h3 className="captlize">
                      <Link to="/BaseraSmartCity">Basera Smart City</Link>
                    </h3>
                  </div>
                  <p className="property_add">SRIKRISHNPORE , P.S. HABRA DIST.DIST- NORTH 24 PGS. ASHOKENAGAR , KOLKATA, WEST BENGAL</p>
                  <div className="property_meta">
                    <div className="list-fx-features">
                      <div className="listing-card-info-icon">
                        <span className="inc-fleat inc-bed">2 Beds</span>
                      </div>
                      <div className="listing-card-info-icon">
                        <span className="inc-fleat inc-type">Corporate</span>
                      </div>
                      <div className="listing-card-info-icon">
                        <span className="inc-fleat inc-area">1,580 sqft</span>
                      </div>
                      <div className="listing-card-info-icon">
                        <span className="inc-fleat inc-bath">2 Bath</span>
                      </div>
                    </div>
                  </div>
                  <div className="property_links">
                    <Link to="/ContactUs" className="btn btn-theme">Request Info</Link>
                    <Link to="/BaseraSmartCity" className="btn btn-theme-light">Property Detail</Link>
                  </div>
                </div>
              </div>
            </div>
            {/* item end */}

            {/* item start */}
            <div className="col-md-6">
              <div className="property_item classical-list">
                <div className="image">
                  <Link to="/EcoSmartCityPhaseII">
                    <img src="assets/img/eco-smart-city-phase-II-home.webp" alt="latest property" className="img-fluid" />
                  </Link>
                  <div className="sb-date">
                    <span className="tag">
                      <i className="ti-calendar" /> From 1/8/2023
                    </span>
                  </div>
                </div>
                <div className="proerty_content">
                  <div className="proerty_text">
                    <h3 className="captlize">
                      <Link to="/EcoSmartCityPhaseII">Eco Smart City - Phase II</Link>
                    </h3>
                  </div>
                  <p className="property_add">I-land Park, New Town, Beside Manicasa 2 opp (Eco Space) Kolkata - 700156</p>
                  <div className="property_meta">
                    <div className="list-fx-features">
                      <div className="listing-card-info-icon">
                        <span className="inc-fleat inc-bed">2 Beds</span>
                      </div>
                      <div className="listing-card-info-icon">
                        <span className="inc-fleat inc-type">Corporate</span>
                      </div>
                      <div className="listing-card-info-icon">
                        <span className="inc-fleat inc-area">1,580 sqft</span>
                      </div>
                      <div className="listing-card-info-icon">
                        <span className="inc-fleat inc-bath">2 Bath</span>
                      </div>
                    </div>
                  </div>
                  <div className="property_links">
                    <Link to="/ContactUs" className="btn btn-theme">Request Info</Link>
                    <Link to="/EcoSmartCityPhaseII" className="btn btn-theme-light">Property Detail</Link>
                  </div>
                </div>
              </div>
            </div>
            {/* item end */}
          </div>
        </div>
      </section>
      {/* ============================ Property List End ================================== */}
      {/* ============================ Location List Start ================================== */}
      <section className="pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="sec-heading2 center">
                <div className="sec-left">
                  <h3>Find properties in these cities</h3>
                  <p>Find new &amp; featured property for you.</p>
                </div>
                <div className="sec-right">
                  <a href="half-map.html">
                    View All
                    <i className="ti-angle-double-right ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* location list start */}
          <div className="row">
            {/* item start */}
            <div className="col-md-3">
              <a href="javascript:void(0)" className="img-wrap">
                <div className="img-wrap-content visible">
                  <h4>Kolkata</h4>
                  <span>24 Properties</span>
                </div>
                <div
                  className="img-wrap-background"
                  style={{ backgroundImage: "url(assets/img/kolkata.webp)" }}
                />
              </a>
            </div>
            {/* item end */}
            
          </div>
          {/* location list end */}
        </div>
      </section>
      {/* ============================ Location List End ================================== */}
      {/* ============================ Browse Place ================================== */}
      <section
        className="image-cover"
        style={{ background: "url(assets/img/new-banner-3.jpg) no-repeat" }}
        data-overlay={3}
      >
        <div className="ht-50" />
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-10">
              <div className="home-slider-container">
                {/* Slide Title */}
                <div className="home-slider-desc">
                  <div className="modern-pro-wrap">
                    <span className="property-type">For Sale</span>
                    <span className="property-featured theme-bg">Featured</span>
                  </div>
                  <div className="home-slider-title mb30">
                    <h3>
                      <a href="javascript:void(0)">Basera Smart City</a>
                    </h3>
                    <span>
                      <i className="lni-map-marker" /> SRIKRISHNPORE , P.S. HABRA DIST.DIST- NORTH 24 PGS. ASHOKENAGAR , KOLKATA, WEST BENGAL
                    </span>
                  </div>
                  
                  
                  <Link to="/BaseraSmartCity" className="read-more text-white">
                    View Details <i className="fa fa-angle-right" />
                  </Link>
                </div>
                {/* Slide Title / End */}
              </div>
            </div>
          </div>
        </div>
        <div className="ht-50" />
      </section>
      {/* ============================ Browse Place End ================================== */}
      
      
    </>

  )
}

export default MidIndex
