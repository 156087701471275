//import logo from './logo.svg';
import './App.css';

import ScrollToTop from './scroll-to-top/ScrollTop.jsx';
import toast, { Toaster } from "react-hot-toast";

// import Swiper styles here
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import { BrowserRouter, HashRouter } from 'react-router-dom';
import Header from './header/Header';
import Footer from './footer/Footer'
import { Route, Routes } from 'react-router-dom';
import Home from './home/Home.jsx';
import AboutUs from './about-us/AboutUs.jsx';
import ContactUs from './contact-us/ContactUs.jsx';
import BaseraSmartCity from './basera-smart-city/BaseraSmartCity.jsx';
import EcoSmartCityPhaseII from './eco-smart-city-phase-ii/EcoSmartCityPhaseII.jsx';
import TermsConditions from './terms-conditions/TermsConditions.jsx';
import PrivacyPolicy from './privacy-policy/PrivacyPolicy.jsx';


function App() {
  return (
    <>
      {/* all files are include here start */}
      <div className='green-skin'>
        <HashRouter>
          <Header />
          <Toaster position="bottom-center" reverseOrder={false} />
          <ScrollToTop />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/AboutUs' element={<AboutUs />} />
            <Route path='/ContactUs' element={<ContactUs />} />
            <Route path='/BaseraSmartCity' element={<BaseraSmartCity />} />
            <Route path='/EcoSmartCityPhaseII' element={<EcoSmartCityPhaseII />} />
            <Route path='/TermsConditions' element={<TermsConditions />} />
            <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
        </HashRouter>
      </div>
      {/* all files are include here end */}
    </>
  );
}

export default App;
