import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const ProjectList = () => {
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [lo, setlo] = useState(false)

  const handleMobileChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    setMobile(inputValue.slice(0, 10));
  };

  const handlesubmit = async(e)=>{
    e.preventDefault()
    try {
      setlo(true)
      if(!email || !mobile || !message){
        toast.error("all field are  required")
        return
      }else{

        const req = await fetch('https://indian-niwas.onrender.com/api/contact-us',{
            method:"POST",
            headers:{
                "Content-Type": "application/json",
            },
            body : JSON.stringify({
                "email": email,
                "mobile": mobile,
                "message": message
            })
        })
        const res  = await req.json()
        if(req.ok){
          toast.success(res.message)
        }else{
          toast.error(res.message)
        }
      }

    } catch (error) {
        
    }finally{
      setEmail("")
      setMobile("")
      setMessage("")
      setlo(false)
    }

  }

  return (
    <>
      {/* page banner part start */}
      <section className="spd-wrap bgThemeGray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="slide-property-detail">
                <div className="slide-property-first">
                  <div className="pr-price-into">
                    <h2>Basera Smart City</h2>
                    <span>
                      <i className="lni-map-marker" /> SRIKRISHNPORE , P.S.
                      HABRA DIST.DIST- NORTH 24 PGS. ASHOKENAGAR , KOLKATA, WEST
                      BENGAL
                    </span>
                  </div>
                </div>
                <div className="slide-property-sec">
                  <a className="btn btn-warning text-white m0" href="#.">
                    <i className="ti-download"></i> &nbsp;Download Brochures
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* page banner part end */}

      {/* page body part start */}
      <section className="gray">
        <div className="container">
          <div className="row">
            {/* property main detail */}
            <div className="col-lg-8 col-md-12 col-sm-12">
              {/******** slider start here ********/}
              <div className="mb20">
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img
                      src="assets/img/basera-smart-city-banner-1.webp"
                      className="d-block w-100"
                      alt="Basera Smart City"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img
                      src="assets/img/basera-smart-city-banner-2.webp"
                      className="d-block w-100"
                      alt="Basera Smart City"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
              {/******** slider end here ********/}
              <div className="mb10">
                <label className="badge badge-danger text18">
                  Booking Date - From 1/8/2023
                </label>{" "}
                &nbsp;
                <label className="badge badge-warning text18">
                  Rate- Rs.551/- PER SQ. FT
                </label>
              </div>
              {/* Single Block Wrap */}
              <div className="block-wrap">
                <div className="block-header">
                  <h4 className="block-title">Description</h4>
                </div>
                <div className="block-body">
                  <p>
                    A LAYOUT PLAN ON L.R. DAG NO. 919,921,998,999,373,939 AT
                    MOUZA -ASUDI, J.L. NO-8, SHEET NO X, M CODE-1506008 UNDER
                    SRIKRISHNAPORE GRAM PANCHAYAT P.S. HABRA. DIST- NORTH 24
                    PARGANAS
                  </p>
                  <ul class="avl-features d-block text-success">
                    <li className="width100">
                      <b>BOOKING AMOUNT ONLY 25,000/-</b>
                    </li>
                    <li className="width100">
                      <b>AGREEMENT TO BE DONE IN 15 TO 25 DAYS (25%) Payment</b>
                    </li>
                    <li className="width100">
                      <b>EASY PAYMENT WITH 36 INSTALLMENTS</b>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Single Block Wrap */}
              <div className="block-wrap">
                <div className="block-header">
                  <h4 className="block-title">Ameneties</h4>
                </div>
                <div className="block-body">
                  <ul className="avl-features third">
                    <li>Guard Post</li>
                    <li>Lake (Water Body)</li>
                    <li>Street Light</li>
                    <li>Boating</li>
                    <li>24x7 Security</li>
                    <li>Sewerage</li>
                    <li>Park</li>
                  </ul>
                </div>
              </div>

              <div className="block-wrap">
                <div className="block-header">
                  <h4 className="block-title">Essential Information</h4>
                </div>
                <div className="block-body">
                  <div className="nearby-wrap">
                    <div className="neary_section_list">
                      <div className="neary_section">
                        <div className="neary_section_first">
                          <h4 className="nearby_place_title">
                            KOLKATA AIRPORT
                          </h4>
                        </div>
                        <div className="neary_section_last">
                          <div className="nearby_place_rate good">
                            35 KM (approx)
                          </div>
                        </div>
                      </div>
                      <div className="neary_section">
                        <div className="neary_section_first">
                          <h4 className="nearby_place_title">
                            ASHOKENAGAR Railway Station
                          </h4>
                        </div>
                        <div className="neary_section_last">
                          <div className="nearby_place_rate mid">
                            6 KM (approx)
                          </div>
                        </div>
                      </div>
                      <div className="neary_section">
                        <div className="neary_section_first">
                          <h4 className="nearby_place_title">SILIGURI NH</h4>
                        </div>
                        <div className="neary_section_last">
                          <div className="nearby_place_rate high">
                            6 KM (approx)
                          </div>
                        </div>
                      </div>
                      <div className="neary_section">
                        <div className="neary_section_first">
                          <h4 className="nearby_place_title">BONGAON NH</h4>
                        </div>
                        <div className="neary_section_last">
                          <div className="nearby_place_rate poor">
                            6 KM (approx)
                          </div>
                        </div>
                      </div>
                      <div className="neary_section">
                        <div className="neary_section_first">
                          <h4 className="nearby_place_title">BARASAT HQ.</h4>
                        </div>
                        <div className="neary_section_last">
                          <div className="nearby_place_rate mid">
                            25 KM (approx)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Single Block Wrap */}
              <div className="block-wrap">
                <div className="block-header">
                  <h4 className="block-title">Upcoming</h4>
                </div>
                <div className="block-body">
                  <ul className="avl-features third">
                    <li>Committee Hall</li>
                    <li>Resort</li>
                    <li>Mandir</li>
                    <li>Bunglow</li>
                    <li>Club</li>
                    <li>Flat (Studio, 2BHK, 3BHK)</li>
                    <li>Restaurant</li>
                    <li>CCTV</li>
                    <li>Shopping Departmental Store</li>
                    <li>Power Backup (Generator)</li>
                    <li>Cottage</li>
                    <li>Wi-Fi</li>
                  </ul>
                </div>
              </div>

              {/* Single Block Wrap */}
              <div className="block-wrap">
                <div className="block-header">
                  <h4 className="block-title">Floor Plan</h4>
                </div>
                <div className="block-body">
                  <img
                    src="assets/img/basera-smart-city-plan.webp"
                    className="width100"
                    alt=""
                  />
                </div>
              </div>
              {/* Single Block Wrap */}
              <div className="block-wrap">
                <div className="block-header">
                  <h4 className="block-title">Location</h4>
                </div>
                <div className="block-body">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29387.334546901402!2d87.99815099941169!3d22.971695192292092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f867938cb6ce4d%3A0xd132bbde18f26a15!2sSrikrishnapur%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1701855322914!5m2!1sen!2sin"
                    width="100%"
                    height={300}
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
            {/* property Sidebar */}
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="page-sidebar">
                {/* Quick Enquiry form start */}
                <div className="agent-widget">
                  <h4 className="mb15">Quick Enquiry</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={mobile}
                      onChange={handleMobileChange}
                      className="form-control"
                      placeholder="Your Phone"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      value={message}
                      onChange={(e)=>setMessage(e.target.value)}
                      required
                    />
                  </div>
                  <button onClick={handlesubmit} className="btn btn-theme full-width">
                    {lo ? "Sending....":"Send Message"}
                  </button>
                </div>
                {/* Quick Enquiry form end */}

                {/* Featured Property */}
                <div className="sidebar-widgets">
                  <h4>Featured Property</h4>
                  <div className="sidebar_featured_property">
                    {/* item start */}
                    <div className="sides_list_property">
                      <div className="sides_list_property_thumb">
                        <img
                          src="assets/img/basera-smart-city-banner-1.webp"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="sides_list_property_detail">
                        <h4>
                          <Link to="/BaseraSmartCity">Basera Smart City</Link>
                        </h4>
                        <span>
                          <i className="ti-location-pin" />
                          NORTH 24 PGS, KOLKATA
                        </span>
                      </div>
                    </div>
                    {/* item end */}

                    {/* item start */}
                    <div className="sides_list_property">
                      <div className="sides_list_property_thumb">
                        <img
                          src="assets/img/eco-smart-city-phase-II-1.webp"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="sides_list_property_detail">
                        <h4>
                          <Link to="/EcoSmartCityPhaseII">
                            Eco Smart City - Phase II
                          </Link>
                        </h4>
                        <span>
                          <i className="ti-location-pin" />
                          NEWTOWN, KOLKATA
                        </span>
                      </div>
                    </div>
                    {/* item end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* page body part end */}
    </>
  );
};

export default ProjectList;
